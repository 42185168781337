<template>
  <div class="page">
    <div class="main service">
      <img src="/service/640.png" alt="" />
      <img src="/service/table.png" alt="" />
      <h1>PCGS(上海)评级服务</h1>
      <p style="color: red; font-size: 18px">
        上海辉龙阁实体店入驻上海云洲商厦，距离PCGS上海办公室仅三余公里，每周两次送评及取币；客户送评机制币，按币状态做出最佳分类，专业高效值得信赖。
      </p>
      <p style="color: #F6C142; text-align: center; font-size: 18px">评级费九折，可养护，完成评级后统一收费</p>
      <img src="/service/1.png" alt="" />
      <p>
        **
        所有样币、错体币、1925年前的俄罗斯卢布（1卢布及以上）、中国军阀人像币、中国臆造币都必须使用普快或以上的服务等级。错体币需额外支付90元/枚。
      </p>
      <p>†预估担保费按钱币最终等级的公允价值1%收取，但不低于35元/枚。</p>
      <p>
        钱币的价值以该等级的PCGS价格指南或钱币的申报价值（无价格指南的情况下）确定。PCGS
        将保留决定钱币价值的最终解释权。
      </p>
      <p>
        如选择罕品服务，需在该服务等级评级费基础上额外收取 1% 的预估担保费。
      </p>
      <p>
        如选择重新评级服务，若成功评级到更高分数，需在该服务等级评级费基础上额外收取
        1% 的预估担保费。
      </p>
      <p>
        如选择钱币换装服务，可在送评表正面指定希望的最低分数（此分数可高于封装盒上的分数，也可低于封装盒上的分数）,
        PCGS
        只会将达到或超过指定最低分数的钱币封装入盒。若换装成功，需在该服务等级评级费基础上额外收取
        1% 的预估担保费。
      </p>
      <img src="/service/2.png" alt="" />
      <img src="/service/3.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      shop_id: null,
    };
  },

  created() {
    this.shop_id = this.$route.params.shop_id;
  },
  mounted() {},
  methods: {},
};
</script>